/* center all ps and divs */
p {
	text-align: center;
	max-width: 60%;
	margin: 0 auto;
}
h1 {
	text-align: center;
}
.bigMe {
	width: 25%;
	max-width: 350px;
	height: auto;
}
.rowFlex {
	padding: 0 2rem;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
}
footer {
	background: linear-gradient(
		20deg,
		rgba(154, 66, 172, 0.8),
		rgba(221, 131, 75, 0.8)
	);
	box-shadow: inset 0 0 0 3000px rgba(150, 150, 150, 0.192);
	text-align: center;
	padding: 1rem;
	margin-top: 2rem;
	bottom: 0;
	position: fixed;
	border-radius: 10px;
	margin: 2rem;
}
.card {
	background: linear-gradient(
		20deg,
		rgba(154, 66, 172, 0.8),
		rgba(221, 131, 75, 0.8)
	);
	text-align: center;
	border-radius: 10px;
	padding: 1rem;
	margin: 1rem auto;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	transition: 200ms;
	max-width: fit-content;
}
.card img {
	width: 100%;
	height: auto;
	border-radius: 10px;
}
.card:hover {
	transform: scale(1.1);
}
