.projects {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-content: flex-start;
	align-items: stretch;
	margin: 0.5rem;
	height: 80vh;
}

/* Cell with given width */
.showcase1\/2 {
	flex: 0 0 50%;
}
.showcase1\/3 {
	flex: 0 0 33.3333333%;
}
.showcase1\/4 {
	flex: 0 0 25%;
}

.showcaseItem {
	touch-action: pan-y;
	border-radius: 20px;
	transform-style: preserve-3d;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	position: relative;
	min-width: 350px;

	max-width: 50vw;
	padding: 1.4rem;
	cursor: pointer;
}

.showcaseItemStack {
	width: fit-content;
	margin-bottom: 0.5rem;
}

.showcaseItemStack img {
	padding: 0.2rem;
}

.showcaseItem .showcase {
	color: #fff;
	height: 100%;
	position: relative;
	width: 100%;
	border-radius: 20px;
	transition: all 0.2s ease-out;
}
.showcase .showcaseItemShadow {
	position: absolute;
	top: 5%;
	left: 5%;
	width: 90%;
	height: 90%;
	transition: all 0.2s ease-out;
	box-shadow: 0 8px 30px rgba(14, 21, 47, 0.6);
	z-index: 0;
}
.showcaseItem .showcaseItemText {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	overflow: hidden;
	transform-style: preserve-3d;
	z-index: 2;
}
.showcaseItem .showcaseItemRenderedText {
	position: absolute;
	width: 104%;
	height: 104%;
	top: -2%;
	left: -2%;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	background-size: cover;
	transition: all 0.1s ease-out;
	z-index: 4;
}
.showcaseItem .showcaseItemShine {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 20px;
	background: linear-gradient(
		135deg,
		rgba(255, 255, 255, 0.25) 0%,
		rgba(255, 255, 255, 0) 60%
	);
	z-index: 8;
}
.showcaseItem:hover {
	z-index: 9;
}
.showcaseItem:hover .showcaseItemShadow {
	box-shadow: 0 45px 100px rgba(14, 21, 47, 0.4),
		0 16px 40px rgba(14, 21, 47, 0.4);
}
