h1 {
	letter-spacing: 0.2rem;
}

.hero {
	letter-spacing: normal;
	line-height: 1.5;
	width: 100%;
	height: auto;
	display: flex;
	padding: 2rem 0;
	justify-content: space-evenly;
	flex-direction: row;
	align-items: center;
	letter-spacing: 0.06rem;
	padding-top: 0;
}

.heroLeft {
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	justify-content: center;
	text-align: center;
}
.heroIntro {
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 1rem auto;
	max-width: 75%;
}

.heroRight {
	text-align: center;
	padding-right: 1rem;
	flex-grow: 1;
	width: auto;
	min-width: 240px;
	height: 100%;
}
@media screen and (max-width: 620px) {
	.hero {
		flex-direction: column-reverse;
	}
	.heroLeft {
		width: 100%;
		font-size: 0.9rem;
		text-align: center;
		flex-wrap: wrap;
		min-width: 0;
		padding: 0;
	}
	.bigMe {
		width: 40%;
	}
	.heroRight {
		padding-top: 1.4rem;
		padding-right: 0;
		width: 30vw;
		text-align: center;
	}
}

.heroText {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 1rem;
	align-self: center;
	padding: 0.5rem 0.75rem;
}

#socialsHook {
	padding-top: 1rem;
}
