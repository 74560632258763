/* Dark mode (DEFAULT(Cause its better))*/
:root {
	--primary: #e68346;
	--secondary: #e75a6d;
	--lightBackground: #3a3a3a;
	--background: #1c1c1e;
	--darkBackground: #070707;
	--text: #fffdf7;
	--darkText: #17161b;
	--gradient: linear-gradient(to right, #8a2387, #e94057, #f27121);
	--blackImgFilter: invert(97%) sepia(97%) saturate(12%) hue-rotate(197deg)
		brightness(105%) contrast(104%);
	--whiteImgFilter: ;
}
/* Light mode */
[data-theme="light"] {
	--primary: #975a22;
	--secondary: #592369;
	--lightBackground: #d6d1d1;
	--background: #f0f0f0;
	--darkBackground: #353535;
	--text: #000000;
	--darkText: #ffffff;
	--blackImgFilter: ;
	--whiteImgFilter: invert(97%) sepia(97%) saturate(12%) hue-rotate(197deg)
		brightness(105%) contrast(104%);
}

html {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	font-size: calc(15.4px + 0.5vw);
	color: var(--text);
	background-color: var(--background);
}

body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	font-family: "Montserrat", sans-serif;
	background-color: var(--background);
	transition: 300ms;
}

a {
	color: var(--text);
	text-decoration: none;
	transition: color 200ms;
}

a:hover {
	color: var(--darkText);
}

button {
	background-color: transparent;
	border: none;
	color: var(--darkBlue);
	padding: 0;
	margin: 0;
}

.logo {
	/* if darkmode invert colors */
	filter: var(--blackImgFilter);
	padding: 0.5rem;
	transition: filter 200ms;
}

.logo:hover {
	cursor: pointer;
}
