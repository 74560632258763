.socials {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
}

.socialsLogo {
	height: 3rem;
	width: auto;
}

.socialsLogo:hover {
	filter: drop-shadow(0 0 0.1rem #000) var(--blackImgFilter);
}
